<template>
	<div class="my-watch">
		<el-popconfirm
			title="删除当前页看单吗？"
			@onConfirm="removeAllWatch()"
			class="remove"
			v-show="moiveList.length"
		>
			<span slot="reference" style="width: 140px;height: 50px;display: flex;justify-content: center;align-items: center;">清除全部看单</span>
		</el-popconfirm>

		<div class="moive-list-history">
			<div class="moive-item" v-for="(item, index) in moiveList" :key="index">
				<div class="moive-img" @click="goDetail(item.videoId)">
					<!-- <img :src="getImg(item.poster, '200', '288')" alt="" /> -->
					<lazy-pic :array="[item.poster, '200', '288']" :type="'bigPoster'" :isWrapper="'a'" />
				</div>
				<div class="moive-title-box">
					<div class="title-1" @click="goDetail(item.videoId)">{{ item.name }}</div>
					<div class="title-2">
						<span>{{ object[item.type] }}</span>

						<el-popconfirm title="看单中移除此视频吗？" @onConfirm="removeWatch(item)">
							<el-button type="text" style="color: rgba(255, 52, 101, 1)" slot="reference"
								>移除</el-button
							>
						</el-popconfirm>
					</div>
				</div>
			</div>
		</div>
		<div class="no-work" v-show="!moiveList.length">
			<img :src="emptyIcon" alt="" />
			<span>暂无内容</span>
		</div>
		<page-select v-show="moiveList.length" :pagination="pagination" @pageChange="pageChange"></page-select>
	</div>
</template>

<script>
import pageSelect from "./page-select"
export default {
	name: "my-watch",
	data() {
		return {
			moiveList: [],
			wantIcon: require("@assets/explore/want.png"),
			emptyIcon: require("@assets/explore/empty.png"),
			pageSize: 20,
			currentPage: 1,
			pagination: {
				currentPage: 1,
				pageSize: 0,
				numFound: 0,
				totalPage: 0,
			},
			object: {
				movie: "电影",
				tv: "剧集",
			},
		}
	},
	methods: {
    async removeAllWatch() {

			const result = await this.api.removeWatch({
				lookIds: this.moiveList.map(i => i.lookId),
			})
			console.log(result)
			if (result.code === 1) {
				this.$message.success("清除成功")
				this.init()
			} else {
				this.$message.error(result.msg)
			}
		},
		async init() {
			const result = await this.api.getPlayList({
				currentPage: this.pagination.currentPage,
			})
			console.log(result)
			this.moiveList = result.data.list
			this.pagination = result.data.pagination
		},
		pageChange(e) {
			this.pagination.currentPage = e
			this.init()
			console.log(e)
		},
		async removeWatch(item) {
			const result = await this.api.removeWatch({
				lookIds: [item.lookId],
			})
			if (result.code === 1) {
				this.$message.success("移除成功")
				this.init()
			} else {
				this.$message.error(result.msg)
			}
		},
	},
	mounted() {
		this.init()
	},
	activated() {
		this.init()
	},
	components: {
		pageSelect,
	},
}
</script>

<style lang="scss" scoped>
.no-work {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 200px;
	img {
		width: 300px;
		height: 210px;
	}
	span {
		font-size: 15px;
		font-family: PingFang SC;
		font-weight: 500;
		line-height: 21px;
		color: #797a8e;
		margin-top: 20px;
		user-select: none;
	}
}
.my-watch {
	position: relative;
	margin-top: 40px;
	.remove {
		width: 140px;
		height: 50px;
		background: rgba(255, 52, 101, 0.1);
		border-radius: 10px;
		position: absolute;
		right: 0;
		top: 0;
		font-size: 15px;
		font-family: PingFang SC;
		font-weight: 500;
		line-height: 21px;
		color: #ff3465;
		display: flex;
		justify-content: center;
		align-items: center;
		user-select: none;
		cursor: pointer;
	}
}
.moive-list-history {
	margin-right: 200px;
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	align-items: center;
	.moive-item {
		margin-right: 40px;
		margin-bottom: 20px;
		.moive-img {
			width: 166px;
			height: 240px;
			position: relative;
			border-radius: 10px;
			overflow: hidden;
			cursor: pointer;
			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 10;
			}

			.moive-tag {
				position: absolute;
				right: 16px;
				top: 12px;
				z-index: 11;
				width: 50px;
				height: 30px;
				background: #121330;
				opacity: 0.9;
				border-radius: 5px;
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 500;
				line-height: 20px;
				color: #ff3465;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
		.moive-title-box {
			width: 100%;
			z-index: 11;

			margin-top: 10px;
			.title-1 {
				font-size: 16px;
				font-family: PingFang SC;
				font-weight: 500;
				line-height: 26px;
				color: #b6b7c3;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				width: 166px;
			}
			.title-1:hover {
				cursor: pointer;
				text-decoration: underline;
			}
			.title-2 {
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 20px;
				color: #797a8e;
				margin-top: 6px;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
			/*.title-2 span:hover {*/
			/*	cursor: pointer;*/
			/*	text-decoration: underline;*/
			/*}*/
		}
	}
}
</style>
